//navbar style

.nav-container {
    display: flex;
    position: fixed;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    max-width: $page-width;
    background-color: white;

    .nav-logo {
        float: left;

        img {
            width: $profile-width;
            border-radius: $profile-border-radius;
            margin: $profile-margin;
        }

        a {
            text-decoration: none;
            font-size: $font-size-lg;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            transition: all 0.2s ease-in-out;
            color: $text-color;

            &:hover {
                color: $primary-color;
            }
        }
    }

    .nav-links {
        float: right;
        text-transform: uppercase;
        font-size: $font-size-lg;
        font-weight: $font-weight-bold;
        overflow: hidden;

        .nav {
            direction: rtl;
        }

        .link-top {
            transition: all 0.2s ease-out;
            position: relative;
            top: -25px;
            opacity: 0;
            z-index: -10;

            &.active {
                top: 0px;
                opacity: 1;
                z-index: 0;
            }
        }

        .link-bottom {
            transition: all 0.2s ease-out;
            position: relative;
            top: 25px;
            opacity: 0;
            z-index: -10;

            &.active {
                top: 0px;
                opacity: 1;
                z-index: 0;
            }
        }

        .menu-button {
            border-radius: 50px;
            float: right;
            display: flex;
            overflow: hidden;
            direction: rtl;
            text-align: left;
            align-items: center;
            width: 160px;
            padding: $profile-margin;

            span {
                cursor: pointer;
            }

            .menu-line {
                transition: all 0.4s ease-out;
                width: 20px;
                height: 2px;
                margin: 0px 5px;
                background-color: $text-color;

                &.active {
                    width: 80px;
                    background-color: $primary-color;
                }
            }
        }
    }
}
