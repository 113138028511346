//All app css

//Globale css files
@import './Variables.scss';
@import './Bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

//global css
html,
body {
  font-family: $font-family;
  scroll-padding-top: 60px;
  -webkit-font-smoothing: antialiased;

  * {
    box-sizing: border-box;
  }


}

body {
  overflow: hidden;

  &.done {
    overflow: auto;
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 2rem
}

::-webkit-scrollbar-thumb:hover {
  background: $gray-800;
}

//styles that apply on both mobile and desktop
.link {
  &:focus {
    color: $text-color
  }

  &:hover {
    color: $primary-color  !important;
  }
}


//Default Theme
@media (orientation: landscape) {
  .app-container {
    background-image: url('../assets/side-background.png');
    background-size: contain;
    // background-position: 50% 0;    
    // transition: 0s ease-out;
    // transition-property: background-position;
    height: 100vh;
  }

  .app {
    max-width: $page-width;
    background-color: white;
    overflow: hidden;
    margin: auto;
    box-shadow: 0px 0px 50px 0px rgba(black, 0.4);
  }

  @import './default/Navbar.scss';
  @import './default/Header.scss';
  @import './default/Content.scss';
  @import './default/MouseBubble.scss';
  @import './default/Skills.scss';
  @import './default/Projects.scss';
  @import './default/Splash.scss';
}

//Mobile Theme
@media (orientation: portrait) {
  .app {
    width: 100%;
    overflow: hidden;
    margin: auto;
    box-shadow: 0px 0px 50px 0px rgba(black, 0.4);
  }

  @import './mobile/Navbar.scss';
  @import './mobile/Header.scss';
  @import './mobile/Content.scss';
  @import './mobile/MouseBubble.scss';
  @import './mobile/Skills.scss';
  @import './mobile/Projects.scss';
  @import './mobile/Splash.scss';
}