.header-container {
    display: flex;
    overflow: hidden;
    padding-top: 4rem;

    .photo-container {
        width: 100%;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(0px);

        .profile-container {
            height: 100%;
            margin: 10px;

            .profile-img {
                width: auto;
                height: 110%;
                margin-left: auto;
                display: flex;
                position: relative;
                z-index: -5;
                transition: all 0.4s ease-out;
            }

            .profile-img-bg {
                width: auto;
                height: 100%;
                display: flex;
                margin: auto;
                background-position: right;
                background-size: 800px;
                background-repeat: no-repeat;
                position: relative;
                background-image: url("../../assets/Nebil-Sahin-Header-bg3.jpg");
                z-index: -10;
                transition: all 0.2s ease-out;
            }
        }

        .blur {
            width: 63%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all 0.4s ease-out;
            height: 100%;
            position: absolute;
            box-shadow: 0px 60px 50px 10px rgba(black, 0.4);
            backdrop-filter: blur(15px);

            span {
                font-size: $font-size-xxxl;
                line-height: 1.5rem;
                
                .header-title {
                    font-weight: $font-weight-extrabold;
                    font-size: $font-size-xxxl;
                    line-height: 1.5rem;
                }

                span {
                    font-size: $font-size-xl;
                }

                .red {
                    color: $primary-color;
                }
            }

            .header-arrow {
                position: absolute;
                bottom: 40px;
                left: 30px;
                color: $text-color;
                transition: all 0.2s ease-out;
                text-decoration: none;

                &:hover {
                    color: $primary-color;
                }

                span {
                    font-size: $font-size-xl;
                }

                .icon {
                    position: relative;
                    font-size: $font-size-xxxl;

                    &.animate {
                        animation: move 0.8s ease-out;
                        animation-iteration-count: infinite;
                        animation-direction: alternate;

                        @keyframes move {
                            from {
                                bottom: 20px;
                            }

                            to {
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
    }

    .art-container {
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: relative;
        backdrop-filter: blur(0px);
    }

    .img-container {
        // box-shadow: inset 0em 20px 20px -20px  black;
        margin: auto;
        display: flex;
        position: relative;
        width: 100%;
        transition: all 0.2s ease-out;
        height: 700px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
}
