.project {
    margin: 4rem 0rem;
    display: flex;
    align-items: center;

    .project-img {
        width: 40%;
        background-color: transparent;
        mix-blend-mode: multiply;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s ease-out;

        &:hover img{
            transform: translateY(-30px);
        }
        
        img {
            width: 90%;
            position: relative;
            transition: transform 250ms;
        }

        &.laptop {
            img {
                width: 100%;
            }
        }
    }

    .project-info {
        width: 60%;
        padding: 2rem;
        h2 {
            font-weight: $font-weight-extrabold;
        }
    }
}
