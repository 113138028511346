.splash-container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $primary-color;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > div{
        display: block;
        width: 6rem;
    }
    .loader {
        width: $loader-size;
        height: $loader-size;
        border: 0.3rem solid white;
        border-radius: 50%;
        display: block;
        position: relative;
        animation: rotation 1s linear infinite;

        &:after {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            width: $loader-border-size;
            height: $loader-border-size;
            border-radius: 50%;
            border: 0.3rem solid transparent;
            border-bottom-color: black;
        }
    }
    .container-loading{
        position: relative;
        top: -60px;
    }

    .loading {
        display: block;
        position: relative;
        text-align: center;
        font-weight: $font-weight-bold;
        color: white;
        animation: move 0.6s ease-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;

        @keyframes move {
            from {
                bottom: 5px;
            }

            to {
                bottom: -5px;
            }
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    &.done{
        animation: done 0.6s cubic-bezier(0, 0, 0.2, 1) ;
        top: -200vh;
    }

    @keyframes done {
        from {
            top: 0px;
            border-radius: 0;
        }

        to {
            top: -200vh;
            border-radius: 50%;
        }
    }
}
