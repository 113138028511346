.mouse-bubble {
    position: absolute;
    display: none;
    z-index: 150;
    pointer-events: none;
    will-change: transform;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background-color: white;
    transition: width 0.2s ease-out;
    box-shadow: 0px 0px 10px 0px $gray-500;
    justify-content: center;
    align-items: center;
    
    .mouse-message{
        padding: 0px 20px;
        font-weight: $font-weight-bold;
        color: $gray-500;
        text-align: center;
        white-space: nowrap;
    }
}